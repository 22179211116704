.title-block {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;

  h1 {
    margin-bottom: 40px;
    letter-spacing: 1px;
  }

  @media (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;

    h1 {
      margin-bottom: 20px;
    }
  }
}
