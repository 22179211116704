@use "./mixins" as m;

// TODO: rewrite to use. https://stackoverflow.com/questions/62757419/whats-the-difference-between-import-and-use-scss-rules
@import "./reset";
@import "./variables";
@import "./sections/title-block";
@import "./sections/accordion";
@import "fonts-install";

html,
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: "Roboto", sans-serif;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  position: relative;
  // scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;

  // &:active,
  // &:visited {
  //   color: $black;
  //   outline: none;
  // }
}

* {
  box-sizing: border-box;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  color: $black;
}

h1,
.h1 {
  font-size: 86px;
  line-height: 1.12;

  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 1.32;
  }
}

h2,
.h2 {
  font-size: 36px;
  line-height: 40px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
}

h3,
.h3 {
  font-size: 32px;
  line-height: 36px;
}

h4,
.h4 {
  font-size: 26px;
  line-height: 34px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.link {
  color: $blue;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  line-height: 25px;
  font-weight: 700;
}

.subheader-large {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 26px;
  line-height: 34px;
  color: $black;
}

.subheader-medium {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: $black;
}

.subheader-small {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: $black;
}

.container {
  max-width: 1280px; // 1216px container size in in design
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;

  &_sm {
    max-width: 1040px; // 1008px container size in in design
    padding-left: 16px;
    padding-right: 16px;
  }

  &_xs {
    max-width: 842px; // 1008px container size in in design
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.wrapper {
  &:not(.no-overflow-hidden) {
    overflow: hidden;
  }
}

.width {
  &_lg {
    max-width: 700px !important;
  }
}

.subtitle {
  &_sm {
    @include m.raleway;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 992px) {
    &_sm {
      @include m.raleway;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.opacity {
  &_08 {
    opacity: 0.8;
  }
}

.title {
  &_2xl {
    @include m.raleway;
    font-size: 60px !important;
    line-height: 67px !important;
  }

  &_xl {
    @include m.raleway;
    font-size: 50px;
    line-height: 56px;
  }

  &_lg {
    @include m.raleway;
    font-size: 36px;
    line-height: 40px;
  }

  &_md-lg {
    @include m.raleway;
    font-size: 32px;
    line-height: 36px;
  }

  &_md {
    @include m.raleway;
    font-size: 30px;
    line-height: 39px;
  }

  &_sm {
    @include m.raleway;
    font-size: 22px;
    line-height: 29px;
  }

  &_xs {
    @include m.raleway;
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 992px) {
    &_2xl {
      font-size: 34px !important;
      line-height: 38px !important;
      text-align: left !important;
    }

    &_lg {
      font-size: 24px;
      line-height: 32px;
    }

    // &_md-lg {
    //   font-weight: 700;
    // }

    &_md {
      font-size: 24px;
      line-height: 31px;
    }

    &_xs {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.features {
  font-feature-settings: "pnum" 1, "lnum" 1 !important;
}

.color {
  &_white {
    color: $white;
  }

  &_blue-gray {
    color: $blue-grey;
  }

  &_black {
    color: $black;
  }

  &_dodger-blue {
    color: $dodger-blue;
  }
}

.align {
  &_left {
    text-align: left;
  }

  &_center {
    text-align: center;
  }
}

.background {
  &_blue {
    background-color: $blue;
    color: $white;
  }

  &_ex-white {
    background-color: $ex-white;
    color: $black;
  }
}

.font {
  &_regular {
    font-weight: 500;
  }
  &_bold {
    font-weight: 700;
  }

  &_extra-bold {
    font-weight: 800;
  }
}

.padding {
  &_left {
    &_sm {
      padding-left: 16px;
    }
  }
}

.margin {
  &_bottom {
    &_big {
      margin-bottom: 60px;
    }
  }
}

.text {
  @include m.roboto;

  &_xl {
    @include m.roboto;
    font-size: 20px;
    line-height: 34px;
  }

  &_lg {
    @include m.roboto;
    font-size: 18px;
    line-height: 28px;
  }

  &_md {
    @include m.roboto;
    font-size: 17px;
    line-height: 26px;
  }

  &_sm {
    @include m.roboto;
    font-size: 14px;
    line-height: 22px;
  }

  @media (max-width: 767px) {
    &_md {
      font-size: 14px;
    }

    &_lg {
      font-size: 14px;
      line-height: 22px;
    }

    &_xl {
      font-size: 14px;
      line-height: 22px;
    }

    &_2xl {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.code {
  white-space: pre;

  span {
    font-family: Ubuntu Mono, monospace;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .c-met {
    color: $blue;
  }

  .c-com {
    color: $grey;
  }
}

.block {
  &_center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.cell-hidden {
  @media (max-width: 1200px) {
    display: none;
  }
}

.soon {
  display: inline;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "soon";
    padding: 0px 8px;
    background: #ffb9f8;
    border-radius: 10px;
    transform: rotate(-15deg) translateX(-20px) translateY(10px);
    font-weight: bold;
    font-size: 16px;
    line-height: 154%;
    color: #fff;
  }
}

.beta {
  display: inline;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "beta";
    padding: 0px 8px;
    background: #93e6cf;
    border-radius: 10px;
    transform: rotate(-15deg) translateX(-20px) translateY(10px);
    font-weight: bold;
    font-size: 16px;
    line-height: 154%;
    color: #fff;
  }
}

// .intercom-lightweight-app {
//   display: none;
// }

.fullWidthItem {
  grid-column: 1 / -1;
  max-width: 100vw;
}

.scroll-fixed {
  overflow: hidden;
}
