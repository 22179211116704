@import "~styles/variables";

$borderArrow: 100px;

.dropdown {
  position: relative;

  @media (hover: hover) {
    &:hover {
      & .menu {
        opacity: 1;
        visibility: visible;
        overflow: initial;
        transform: translateY(0);
      }

      & .header {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  & .arrow {
    display: none;
  }

  & .header {
    display: flex;
    align-items: center;

    & .link {
      & > span {
        &:last-child {
          display: none;
        }
      }
    }

    svg {
      transition: all 0.4s ease;
    }

    a,
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: $black;
      margin-right: 6px;
      transition: opacity 0.4s ease;
    }

    span {
      cursor: default;
    }
  }

  & .menu {
    z-index: 10;
    position: absolute;
    top: 150%;
    left: calc(40% - #{$borderArrow});
    background: #fff;
    border: 1px solid #dfe5eb;
    border-radius: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
    overflow: hidden;
    min-width: 311px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    transition-property: opacity, transform, visibility;

    &:after,
    &:before {
      bottom: 100%;
      left: $borderArrow;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
      border-width: 7px;
      margin-left: -7px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #dfe5eb;
      border-width: 9px;
      margin-left: -9px;
    }

    & > * {
      &:not(:last-child) {
        padding-bottom: 32px;
      }
    }

    & .item {
      display: flex;
      align-items: center;
      padding-left: 32px;
      padding-right: 64px;
      white-space: nowrap;

      &_grow {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 5;
        align-items: start;
      }

      & > span {
        width: 48px!important;

        @media (max-width: 1024px) {
          display: none!important;
        }
      }

      & > div {
        margin-left: 11px;

        strong {
          color: $black;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          display: block;
        }

        span {
          color: $blue-grey;
        }

        a {
          transition: opacity 0.3s, color 0.3s;
          display: block;
          &:hover {
            color: $blue;
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    & .arrow {
      display: block;
      width: 100%;
      background: #fff;

      img {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
      }
    }

    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .link {
        padding-bottom: 0;

        a {
          display: none;
        }

        span {
          display: none;

          &:last-child {
            display: block;
          }
        }
      }

      svg {
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
        margin-bottom: 0;
      }

      span,
      a {
        font-size: 26px;
        line-height: 34px;
      }
    }

    & .menu {
      position: fixed;
      top: 64px;
      left: 0;
      width: 100%;
      height: calc(100vh - 64px);
      background: #fff;
      padding: 20px 16px 12px;
      border: none;
      border-radius: 0;
      z-index: 22px;
      min-width: 100%;
      opacity: 0;
      visibility: hidden;

      &:global(._open) {
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
      }

      &:after,
      &:before {
        display: none;
      }

      & .item {
        padding-left: 0;
        padding-right: 0;
        white-space: normal;

        img {
          display: none;
        }

        & > div {
          margin-left: 0;

          span {
            line-height: 1.54;
            color: $black;
            font-size: 14px;
          }
        }
      }

      & > div {
        &:not(:last-child) {
          padding-bottom: 12px;
        }
      }
    }
  }
}

.resources {
  & .menu {
    display: grid;
    width: 632px;
    grid-template-columns: repeat(2, 1fr);
  }
}
