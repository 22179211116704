.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  z-index: 111;
  .container {
    max-width: 425px;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    overflow-x: hidden;
    background: #fff;
    animation: 300ms ease-in-out 0ms sibebar-appearing ;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }
    .menu {
      padding: 20px;
      position: relative;
      .item {
        &:last-child {
          color: #0084ff;
        }
        .clickable {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 26px;
          line-height: 132%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
        }
        .submenu {
          position: absolute;
          top:0;
          right: -100%;
          &.open {
            right: 0;
          }
          transition: right 300ms ease-in-out;
          width: 100%;
          max-width: 100%;
          min-height: 100%;
          background: #fff;
          padding: 20px;
          .back {
            margin-bottom: 24px;
          }
          .link {
            margin-bottom: 24px;
            label {
              display: block;
              font-family: 'Raleway', sans-serif;
              font-style: normal;
              font-weight: 800;
              font-size: 18px;
              line-height: 132%;
            }
            span {
              font-family: 'Roboto', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 154%;
            }
          }

          .sublink {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}

@keyframes sibebar-appearing {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
