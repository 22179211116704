@import "~styles/variables";

.cookiePopup {
  position: fixed;
  display: none;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  transition: all .4s ease-in-out;
  padding: 30px;
  border-radius: 0;
  background: #181818;
  color: #fff;
  &:global(.show) {
    display: block;
  }
  .card {
    margin: auto;
    display: flex;
    gap: 10px;
    max-width: 1200px;
    .content {
      flex: 1;
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      :global(.transparent) {
        color: #ADB3BD;
        border-color: #ADB3BD;
      }
    }
  }
  label {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
    padding-bottom: 10px;
    display: flex;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #ADB3BD;
    max-width: 636px;
  }
  a {
    color: #2e9aff;
  }
}

@media all and (max-width: 663px) {
  .cookiePopup {
    .card {
      flex-wrap: wrap;
      flex-direction: column;
      .actions {
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
      }
    }
  }
}
