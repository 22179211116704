@import "~styles/variables";

.header {
  padding: 22px 0;

  position: sticky;
  top: 0;
  background: #fff;
  z-index: 111;
  border-bottom: 1px solid #FFF;
  &.bordered {
    border-color: #DFE5EB;
  }
  transition: border-bottom-color 300ms ease-in-out;

  & .logo {
    cursor: pointer;
  }

  & .burger {
    // z-index: 40;
    display: none;
    width: 28px;
    height: 28px;
    position: relative;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      backface-visibility: hidden;
      position: absolute;
      background: $black;
      border-radius: 10px;
      height: 3px;
      width: 100%;
      display: block;
      left: 0;
      transition: all 0.35s, 0.3s ease;
    }

    &:before {
      top: 8px;
    }

    &:after {
      bottom: 8px;
    }

    &:global(._active) {
      &:after,
      &:before {
        top: 50%;
        left: 50%;
      }

      &:before {
        backface-visibility: hidden;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        backface-visibility: hidden;
        transform: translate(-50%, -50%) rotate(-225deg);
      }
    }
  }

  & .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    & > .group {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      justify-content: start;
      grid-gap: 0 40px;
      &:nth-child(1){
        justify-content: center;
        flex: 1;
      }
      &:nth-child(2) {
        a:last-child {
          color: #0084ff;
          border: 1px solid #0084FF;
          border-radius: 8px;
          padding: 12px 32px;
        }
      }
      & > a {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: $black;
        transition: color 0.3s ease, opacity 0.3s ease;
        &:hover {
          color: $blue;
          opacity: 0.8;
        }
        &:global(.active) {
          color: $blue;
        }
      }
    }

  }

  @media screen and (max-width: 1024px) {
    padding: 18px 0;

    & .burger {
      display: block;
    }

    & .nav {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      position: fixed;
      top: 130px;
      left: 0;
      width: 100%;
      height: calc(100vh - 64px);
      background: #fff;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-auto-rows: min-content;
      z-index: 20;
      grid-gap: 24px 0;
      padding: 20px 16px 12px;
      transition: all 0.3s ease;

      &:global(._open) {
        opacity: 1;
        visibility: visible;
      }

      & > a {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }
}
