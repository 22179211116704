@import "~styles/variables";

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  min-height: 62px;
  z-index: 10000;
  padding: 12px;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 154%;
  display: flex;
  align-items: center;
  color: $white;
  transition: transform 0.3s;
  transform: translateY(-100%);

  border: 0 solid $dodger-blue !important;
  background: $dodger-blue;
  background: -moz-linear-gradient(90deg, $dodger-blue 0%,  $riptide 100%);
  background: -webkit-linear-gradient(90deg, $dodger-blue 0%, $riptide 100%);
  background: linear-gradient(90deg, $dodger-blue 0%, $riptide 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$dodger-blue,endColorstr=$riptide,GradientType=1);


  &:global(.show) {
    transform: translateY(0%);
  }

  a {
    font-weight: bold;
    font-size: 14px;
    line-height: 154%;
    color: $white;
    margin-left: 12px;
  }

  @media (max-width: 767px) {
    & > * {
      display: block;
      width: 100%;
      text-align: center;
    }
    a {
      margin-left: 0;
    }
  }
}
